import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section, Span, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Наші послуги в Імперія-Друк
			</title>
			<meta name={"description"} content={"Пориньте у світ Імперія-Друк, де кожна послуга, яку ми пропонуємо, є поєднанням майстерності, технологій та індивідуального підходу."} />
			<meta property={"og:title"} content={"Наші послуги | Наші послуги в Імперія-Друк"} />
			<meta property={"og:description"} content={"Пориньте у світ Імперія-Друк, де кожна послуга, яку ми пропонуємо, є поєднанням майстерності, технологій та індивідуального підходу."} />
			<meta property={"og:image"} content={"https://unitas-liss.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://unitas-liss.com/img/102264.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://unitas-liss.com/img/102264.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://unitas-liss.com/img/102264.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://unitas-liss.com/img/102264.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://unitas-liss.com/img/102264.png"} />
			<meta name={"msapplication-TileImage"} content={"https://unitas-liss.com/img/102264.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Наші послуги
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Пориньте у світ Імперія-Друк, де кожна послуга, яку ми пропонуємо, є поєднанням майстерності, технологій та індивідуального підходу. Відкрийте для себе широту і глибину наших можливостей друку, розроблених для задоволення ваших потреб.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image src="https://unitas-liss.com/img/5.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://unitas-liss.com/img/6.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://unitas-liss.com/img/7.jpg" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image src="https://unitas-liss.com/img/8.jpg" display="block" border-radius="32px" />
			</Box>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Послуги
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" color="--greyD2" font="18px/27px --fontFamily-sans">
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Типографічний дизайн на замовлення:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Спеціалізація - створення унікальних і захоплюючих шрифтів для виразного брендингу та персональних проектів.
								<br />
								Пропозиція - створення шрифтів на замовлення, дизайн логотипів та типографічні консультації.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" color="--darkL2" font="18px/27px --fontFamily-sans">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Високоякісні поліграфічні послуги:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Спеціалізація - створення яскравих відбитків з високою роздільною здатністю на різних носіях.
								<br />
								Пропозиція - візитні картки, брошури, плакати, банери та багато іншого з різними варіантами обробки.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--darkL2">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Рішення для цифрового друку:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--greyD2"
							>
								Спеціалізація - швидкий, ефективний і гнучкий друк для невеликих, більш персоналізованих замовлень.
								<br />
								Пропозиція - друк на вимогу зі швидким терміном виконання, збереженням якості та точності передачі кольору.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--darkL2">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Офсетний друк:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--greyD2"
							>
								Спеціалізація - Ідеально підходить для друку великих обсягів, забезпечуючи стабільність та економічну ефективність.
								<br />
								Пропозиція - масові замовлення на листівки, брошури та великомасштабні друковані кампанії.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});